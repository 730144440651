<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="800px"
          persistent>
  <v-card class="d-flex flex-column">
    <v-card-title>
      {{getTitle}}
      <v-spacer/>
      <v-btn icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">
    <!--dados da matricula-->
    <v-col cols="12" class="">
        <v-row>
            <v-col cols="12" md="4">
                <b>Tipo Plano</b>: {{item.tipoPlano === 'M' || 'MENSAL'}}
            </v-col>
            <v-col cols="12" md="4">
                <b>Local</b>: {{item.descricaoIdLocal}}
            </v-col>
            <v-col cols="12" md="4">
                <b>Curso(s)</b>: {{ item.cursos.map(d => d.descricao_idCurso).join(', ') }}
            </v-col>
        </v-row>
    </v-col>   
    <!--card cobrança de dias-->
    <v-col cols="12" class="">
        <v-row>
            <v-col cols="12" sm="4">
                <b>Valor da Matícula</b>: {{'R$ ' + item.valMatricula}}
            </v-col>
            <v-col cols="12" sm="4">
                <b>Dia do Vencimento</b>: {{item.diaVencimento}}
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <b>Valor desconto</b>: {{'R$ ' + item.valDesconto}}
            </v-col>
        </v-row>
    </v-col>
    <!--dias selecionados-->
    <v-col cols="12" md="12">
        <v-icon large left >mdi-format-list-checks</v-icon><strong>Aulas</strong>
        <v-divider/>
        <template>
            <v-data-table
                :headers="headers"
                :items="item.dias"
                :items-per-page="5"
                class="elevation-1"
            ></v-data-table>
        </template>
    </v-col>

    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn outlined color="primary" @click="$emit('input', false)">fechar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

export default {
  name: "DetalheMatriculaDialog",
  props: ['value','item'],
  data () {
    return {
        headers: [
            {
                text: 'Quadra',
                align: 'start',
                value: 'descricao_idQuadra'
            },
            {
                text: 'Tipo Turma',
                align: 'start',
                value: 'descricao_idTipoTurma'
            },
            {
                text: 'Professor',
                align: 'start',
                value: 'nome_idProfessor'
            },
            {
                text: 'Horário',
                align: 'start',
                value: 'hora_inicio_idHorario'
            },
            {
                text: 'Dia da Semana',
                align: 'start',
                value: 'descDiaSemana'
            },
        ]
    }
  },
  computed: {
    getTitle() {
      return 'Detalhe matrícula:  ' + this.item?.nomeIdCliente
    },
  },

}
</script>

<style scoped>

</style>