"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateExportDisponibilidadeURI = exports.downloadDisponibilidadeReport = exports.findDisponibilidade = exports.remove = exports.update = exports.create = exports.findOne = exports.findAll = void 0;
const core_1 = require("./core");
function findAll(page = 1, search) {
    return core_1.get('Quadra', 'lista', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAll = findAll;
function findOne(id) {
    return core_1.get('Quadra', 'detalhe', { id });
}
exports.findOne = findOne;
function create(data) {
    return core_1.post('Quadra', 'cadastro', data);
}
exports.create = create;
function update(id, data) {
    return core_1.put('Quadra', 'update', Object.assign({ id }, data));
}
exports.update = update;
function remove(id) {
    return core_1.del('Quadra', 'exclui', { id });
}
exports.remove = remove;
/*
    Esse daqui é pra quebrar a cabeça de qualquer cidadão, boa sorte!
 */
function findDisponibilidade(search) {
    return core_1.get('DisponibilidadeQuadra', 'ocupacaoDasQuadrasPorSemana', search)
        .then(({ dados }) => {
        const dias = Object.keys(dados);
        return dias.map(dia => {
            const horarios = dados[dia];
            const horario = Object.keys(horarios)[0];
            const tipos = Object.keys(dados[dia][horario]);
            return {
                dia,
                horario: horario.substring(0, 5),
                tipos: tipos.map(tipo => {
                    const quadras = dados[dia][horario][tipo];
                    return {
                        tipo,
                        quadras: Object.keys(quadras).map(quadra => {
                            const quadraInfo = quadras[quadra];
                            if (quadraInfo == null || (quadraInfo.length == 0))
                                return null;
                            const quadraKey = Object.keys(quadraInfo)[0];
                            return ({
                                nome: quadra,
                                info: Object.assign({}, quadraInfo[quadraKey])
                            });
                        }).filter(value => value != null)
                    };
                })
            };
        });
    });
}
exports.findDisponibilidade = findDisponibilidade;
function downloadDisponibilidadeReport(search) {
    return core_1.downloadFile('Relatorios', 'relatorioOcupacaoDasQuadrasPorSemana', search);
}
exports.downloadDisponibilidadeReport = downloadDisponibilidadeReport;
function generateExportDisponibilidadeURI(search) {
    return process.env.VUE_APP_API + core_1.generateUrl('Relatorios', 'relatorioOcupacaoDasQuadrasPorSemana', search);
}
exports.generateExportDisponibilidadeURI = generateExportDisponibilidadeURI;
